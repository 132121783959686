export default class Ampifire {
	daysUntilExpiration = 7;

	async init() {
		this.track(this.getUrlParams());
		this.startAEventWebinarTracking();
	}

	getUrlParams() {
		return new URLSearchParams({
			...Object.fromEntries(this.getScriptParams()),
			...Object.fromEntries(this.getPageParams())
		});
	}

	track(params: URLSearchParams) {
		const data = new FormData();
		data.append('json', JSON.stringify(this.getPageCookies()));

		fetch(['https://ampifire.com/ta', params].filter(Boolean).join('?'), {
			credentials: 'omit',
			method: 'POST',
			body: data
		})
			.then(response => {
				return response.json();
			})
			.then(data => {
				for (const key in data) {
					// eslint-disable-next-line no-console
					// console.log(key, data[key]);
					this.setCookie(key, data[key]);
					if (key === 'profileId') {
						this.setVisitorIdToBuyLinks(data[key]);
					}
					if (key === 'linkInfo') {
						this.populateMunchaffEncodedFormInput(data[key]);
						this.populateAEventCustomTagInput(data[key]);
					}
				}
			});
	}

	getScriptParams() {
		const scriptSrc = document.currentScript?.getAttribute('src');

		if (scriptSrc) {
			const url = new URL(scriptSrc);

			return url.searchParams;
		}

		return new URLSearchParams();
	}

	getPageParams() {
		return new URLSearchParams(window.location.search);
	}

	getPageCookies(): Array<string> {
		const cookiesString: string = document.cookie;
		const cookiesArray: Array<string> = cookiesString
			.split(';')
			.map(cookie => cookie.trim());

		return cookiesArray;
	}

	getCookieExpiration(): number {
		return this.daysUntilExpiration * 24 * 60 * 60 * 1000;
	}

	setCookie(name: string, value: string): void {
		const expirationDate: Date = new Date();
		expirationDate.setTime(
			expirationDate.getTime() + this.getCookieExpiration()
		);
		const expires = `expires=${expirationDate.toUTCString()}`;
		document.cookie = `${name}=${value};${expires};path=/`;
	}

	setVisitorIdToBuyLinks(id: string): void {
		const links: NodeList = document.querySelectorAll('body a');

		links.forEach((link: Node) => {
			if (
				link instanceof HTMLAnchorElement &&
				link.href.includes('temporary_sale_pages')
			) {
				const url = new URL(link.href);
				url.searchParams.set('profileId', id);
				link.href = url.href;
			}
		});
	}

	populateMunchaffEncodedFormInput(code: string): void {
		const formInput = document.querySelector(
			'input[name="munchaff_encoded_id"]'
		) as HTMLInputElement;

		if (formInput) {
			formInput.value = code;
		}
	}

	populateAEventCustomTagInput(code: string): void {
		setTimeout(() => {
			const formInput = document.querySelector(
				'input[name="customtag"]'
			) as HTMLInputElement;
			if (formInput) {
				formInput.value = code;
			}
		}, 1000);
	}

	startAEventWebinarTracking(): void {
		const emailHolder = document.getElementById(
			'ae_holder_email-ta'
		) as HTMLElement;

		if (!emailHolder) {
			return;
		}

		if (emailHolder.textContent?.includes('{{') === true) {
			// eslint-disable-next-line no-console
			console.log(emailHolder.textContent);
			window.setTimeout(this.startAEventWebinarTracking, 500);
			return;
		}

		if (!emailHolder.textContent) {
			return;
		}

		this.setCookie('email', emailHolder.textContent);
		this.track(this.getUrlParams());
	}
}
